import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import './product.css';

const product = [
  {
    title: "Digital Marketing ",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ",
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/digital-marketing-scaled.jpg"),
  },
  {
    title: "Graphics Design",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ", 
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/Graphic-Design-Course.-1.png"),
  },
  {
    title: "Freelancing Basics",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ", 
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/download (2).png"),
  },
  {
    title: "Blog System",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ",
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/blog.png"),
  },
];

const services = [
  {
    title: "Digital Marketing ",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ",
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/digital-marketing-scaled.jpg"),
  },
  {
    title: "Graphics Design",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ", 
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/Graphic-Design-Course.-1.png"),
  },
  {
    title: "Freelancing Basics",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ", 
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/download (2).png"),
  },
  {
    title: "Blog System",
    text: "Analysts & Developers of TechKnowGram Limited love creating application and tools ",
    text1:" to solve your specific business issues but, our first job, as creative software specialists, is to listen you. We want to hear about your business challenges so that we can  ",
    src: require("../../assets/Image/blog.png"),
  },
];

function ProductItem({ item, isExpanded, toggleExpand }) {
  return (
    <div className="col-md-3">
      <Card className="lara1" style={{ height: 'auto', alignItems: 'center' }}>
        <Card.Img variant="top" src={item.src} />
        <Card.Body>
          <Card.Title className='fontfa' style={{ fontWeight: 'bold', textAlign: 'center' }}>{item.title} </Card.Title>
          <Card.Text className='fontfa'>
            {item.text} {isExpanded && <>{item.text1}</>}{' '}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={toggleExpand}
            >
              ... Readmore
            </span>{' '}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

function ServiceItem({ item, isExpanded, toggleExpand }) {
  return (
    <div className="col-md-3">
      <Card className="lara1" style={{ height: 'auto', alignItems: 'center' }}>
        <Card.Img variant="top" src={item.src} />
        <Card.Body>
          <Card.Title className='fontfa' style={{ fontWeight: 'bold', textAlign: 'center' }}>{item.title}</Card.Title>
          <Card.Text className='fontfa'>
            {item.text} {isExpanded && <>{item.text1}</>}{' '}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={toggleExpand}
            >
              ... Readmore
            </span>{' '}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

const itemsPerPage = 4;

const ProductSlide = () => {
  const [expandedProducts, setExpandedProducts] = useState({});
  const [expandedServices, setExpandedServices] = useState({});
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const toggleExpandProduct = (index) => {
    setExpandedProducts((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleExpandService = (index) => {
    setExpandedServices((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderProductItems = () => {
    const productStartIndex = (activePage - 1) * itemsPerPage;
    const productEndIndex = productStartIndex + itemsPerPage;
    const paginatedProducts = product.slice(productStartIndex, productEndIndex);

    return paginatedProducts.map((item, index) => (
      <ProductItem
        item={item}
        isExpanded={!!expandedProducts[productStartIndex + index]}
        toggleExpand={() => toggleExpandProduct(productStartIndex + index)}
        key={index}
      />
    ));
  };

  const renderServiceItems = () => {
    return services.map((item, index) => (
      <ServiceItem
        item={item}
        isExpanded={!!expandedServices[index]}
        toggleExpand={() => toggleExpandService(index)}
        key={index}
      />
    ));
  };

  return (
    <div className="">
      <h1 className="text-center " style={{ display:"flex",justifyContent:"center",alignItems:"center",fontSize:"24px",margin:"10px",padding:"10px" }}>
        Products
      </h1>
      <div style={{ justifyContent: "center" }} className="row">{renderProductItems()}</div>
      <div className="text-center">
        <div className="">
          <Pagination style={{ justifyContent: "center" }}>
            {Array.from({ length: Math.ceil(product.length / itemsPerPage) }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === activePage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </div>
      <h1 className="text-center " style={{ display:"flex",justifyContent:"center",alignItems:"center",fontSize:"24px",margin:"10px",padding:"10px" }}>
        Services
      </h1>
      <div style={{ justifyContent: "center" }} className="row">{renderServiceItems()}</div>
    </div>
  );
};

export default ProductSlide;
