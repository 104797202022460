import React from "react";
import homeBanner from "../../assets/Image/client3.jpg";
import Footer from "../../component/Footer/Footer";
import Banner from "../../component/HomeBanner/HomeBanner";
import Nav1 from "../../component/Nav/Nav1";
import ProductSlide from '../../component/product/Product'

// import clientProduct1 from "../../assets/Image/clientProduct (1).jpg";
// import clientProduct2 from "../../assets/Image/clientProduct (2).jpg";
// import clientProduct3 from "../../assets/Image/clientProduct (3).jpg";
// import clientProduct4 from "../../assets/Image/clientProduct (4).png";
// import clientProduct5 from "../../assets/Image/clientProduct (5).png";
// import clientProduct6 from "../../assets/Image/clientProduct (6).png";
// import clientProduct7 from "../../assets/Image/clientProduct (7).png";
// import clientProduct8 from "../../assets/Image/clientProduct (8).png";

// const clientProduct = [
//   {
//     src: require("../../assets/Image/01.png"),
//   },
//   {
//     src: require("../../assets/Image/02.png"),
//   },
//   {
//     src: require("../../assets/Image/03.png"),
//   },
//   {
//     src: require("../../assets/Image/04.png"),
//   },
//   {
//     src: require("../../assets/Image/05.png"),
//   },
//   {
//     src: require("../../assets/Image/06.png"),
//   },
//   {
//     src: require("../../assets/Image/07.png"),
//   },
//   {
//     src: require("../../assets/Image/08.png"),
//   },
//   {
//     src: require("../../assets/Image/09.png"),
//   },
//   {
//     src: require("../../assets/Image/010.png"),
//   },
//   {
//     src: require("../../assets/Image/011.png"),
//   },
//   {
//     src: require("../../assets/Image/012.png"),
//   },
//   {
//     src: require("../../assets/Image/013.png"),
//   },
//   {
//     src: require("../../assets/Image/014.png"),
//   },
//   {
//     src: require("../../assets/Image/015.png"),
//   },
//   {
//     src: require("../../assets/Image/016.png"),
//   },
//   {
//     src: require("../../assets/Image/017.png"),
//   },
//   {
//     src: require("../../assets/Image/018.png"),
//   },
//   {
//     src: require("../../assets/Image/019.png"),
//   },
//   {
//     src: require("../../assets/Image/020.png"),
//   },
//   {
//     src: require("../../assets/Image/021.png"),
//   },
//   {
//     src: require("../../assets/Image/022.png"),
//   },
//   {
//     src: require("../../assets/Image/023.png"),
//   },
//   {
//     src: require("../../assets/Image/024.jpg"),
//   },
//   {
//     src: require("../../assets/Image/025.png"),
//   },
//   {
//     src: require("../../assets/Image/026.png"),
//   },
//   {
//     src: require("../../assets/Image/027.png"),
//   },
//   {
//     src: require("../../assets/Image/028.png"),
//   },
//   {
//     src: require("../../assets/Image/029.png"),
//   },
//   {
//     src: require("../../assets/Image/030.png"),
//   },
//   {
//     src: require("../../assets/Image/031.png"),
//   },
//   {
//     src: require("../../assets/Image/032.png"),
//   },
//   {
//     src: require("../../assets/Image/033.png"),
//   },
//   {
//     src: require("../../assets/Image/034.png"),
//   },
//   {
//     src: require("../../assets/Image/035.png"),
//   },
//   {
//     src: require("../../assets/Image/036.png"),
//   },
//   {
//     src: require("../../assets/Image/037.png"),
//   },
//   {
//     src: require("../../assets/Image/038.png"),
//   },
//   {
//     src: require("../../assets/Image/039.jpg"),
//   },
//   {
//     src: require("../../assets/Image/040.png"),
//   },
//   {
//     src: require("../../assets/Image/041.png"),
//   },
//   {
//     src: require("../../assets/Image/042.png"),
//   },
//   {
//     src: require("../../assets/Image/043.png"),
//   },
//   {
//     src: require("../../assets/Image/044.png"),
//   },
//   {
//     src: require("../../assets/Image/045.png"),
//   },
//   {
//     src: require("../../assets/Image/046.png"),
//   },
//   {
//     src: require("../../assets/Image/047.png"),
//   },
//   {
//     src: require("../../assets/Image/048.png"),
//   },
//   {
//     src: require("../../assets/Image/049.png"),
//   },
//   {
//     src: require("../../assets/Image/050.png"),
//   },
//   {
//     src: require("../../assets/Image/051.png"),
//   },
//   {
//     src: require("../../assets/Image/052.png"),
//   },
//   {
//     src: require("../../assets/Image/053.png"),
//   },
//   {
//     src: require("../../assets/Image/054.png"),
//   },
//   {
//     src: require("../../assets/Image/055.png"),
//   },
//   {
//     src: require("../../assets/Image/056.png"),
//   },
//   {
//     src: require("../../assets/Image/057.png"),
//   },
//   {
//     src: require("../../assets/Image/058.png"),
//   },
//   {
//     src: require("../../assets/Image/059.png"),
//   }, 
//   {
//     src: require("../../assets/Image/060.png"),
//   },
// ];

export default function Clients() {
  return (
    <div>
      <Nav1 />
      <Banner image={homeBanner} header={"Products"} />
      <ProductSlide/>
      <Footer/>
    </div>
  );
}
