import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/Image/logo_aa.jpg";
import "./Nav.css";
import { Link } from 'react-router-dom';

function Nav1() {
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [search, setSearch] = useState("d-none");

  const handelInpurSearch = () => {
    setSearch((search) => (search === "d-none" ? "d-block" : "d-none"));
  };

  const navref = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  useEffect(() => {
    console.log("scrolled", navref);
    if (scrolled && !isMobile) {
      navref.current.classList.add("scrolled");
    } else {
      navref.current.classList.remove("scrolled");
    }
  }, [scrolled, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      navref.current.classList.remove("scrolled");
    }
  }, [isMobile]);

  return (
    <Navbar  ref={navref} bg="" expand="lg">
      <a href="/">
        <img className="logonav" src={logo} alt="" />
      </a>
      <Container className="dunno">
        {/* <Navbar.Brand href="/">Home</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          className="justify-content-end"
          id="basic-navbar-nav"
        >
          <Nav className="mxy-auto fontfa">
            <Nav.Link as={Link} to="/" style={{fontWeight:"500",}}>Home</Nav.Link>
            {/* <Nav.Link as={Link} to="/about" style={{fontWeight:"500"}}>About</Nav.Link> */}
            <Nav.Link as={Link} to="/client" style={{fontWeight:"500"}}>Products</Nav.Link>
            <Nav.Link as={Link} to="/product" style={{fontWeight:"500"}}> Services</Nav.Link>
            <Nav.Link as={Link} to="/job" style={{fontWeight:"500"}}>Partners </Nav.Link>
            {/* <NavDropdown title="News" id="basic-nav-dropdown">
              <NavDropdown.Item href="blog">Blog</NavDropdown.Item>
              <NavDropdown.Item href="news">News</NavDropdown.Item>
              <NavDropdown.Item href="profile"> Profile </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link as={Link} to="/contact" style={{fontWeight:"500"}}>Contact</Nav.Link>
            
          </Nav>
          <div className="" style={{display:"flex"}}>
            <div className="">
          <Nav.Link as={Link} to="/login" style={{backgroundColor:"rgb(109 95 253)",margin:"10px",color:"white",borderRadius:"5px"}} >Log In</Nav.Link>
          </div>
          <div className="">
            <Nav.Link as={Link} to="/register" style={{backgroundColor:"rgb(109 95 253)",margin:"10px",color:"white",borderRadius:"5px"}}>Registration</Nav.Link>
            </div>
            </div>
        </Navbar.Collapse>
      </Container>
      <Form
        className="d-flex leora"
        style={{ padding: "5px", marginRight: "25px" }}
      >
        <Form.Control
          type="search"
          placeholder="Search"
          className={"me-2 " + search}
          aria-label="Search"
        />
        <Button variant="outline-success" onClick={handelInpurSearch}>
          <i className="fas fa-search"></i>
        </Button>
      </Form>
    </Navbar>
  );
}

export default Nav1;

// import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import logo from '../../assets/Image/navlogo.png'
// import './Nav.css'

// function NavScrollExample() {
//   return (
//     <Navbar bg="light" expand="lg">
//       <Container fluid>
//         <Navbar.Brand href="#">Navbar scroll</Navbar.Brand>
//         <Navbar.Toggle aria-controls="navbarScroll" />
//         <Navbar.Collapse id="navbarScroll">
//           <Nav
//             className="me-auto my-2 my-lg-0"
//             style={{ maxHeight: '100px' }}
//             navbarScroll
//           >
//             <Nav.Link href="#action1">Home</Nav.Link>
//             <Nav.Link href="#action2">Link</Nav.Link>
//             <NavDropdown title="Link" id="navbarScrollingDropdown">
//               <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action4">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action5">
//                 Something else here
//               </NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Link href="#" disabled>
//               Link
//             </Nav.Link>
//           </Nav>
//           <Form className="d-flex">
//             <Form.Control
//               type="search"
//               placeholder="Search"
//               className="me-2"
//               aria-label="Search"
//             />
//             <Button variant="outline-success">Search</Button>
//           </Form>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default NavScrollExample;
