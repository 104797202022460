import React from "react";
// import './jabad.css'

function Coursal() {
  const logo = [
    {
      logo: require("../../assets/Image/digital-marketing-scaled.jpg"),
    },
    {
      logo: require("../../assets/Image/Graphic-Design-Course.-1.png"),
    },
   
    {
      logo: require("../../assets/Image/download (2).png"),
    },
 
  ];
  return (
    <div className="">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          {logo.map((item, i) => (
            <div class={`carousel-item ${i===0 ? "active":""}`}>
              <img style={{height:"350px", borderRadius:"10px"}} class="d-block " src={item.logo} alt="First slide" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Coursal;
