import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./Page/About/About";
import AdminDashboard from "./Page/AdminDashboard/AdminDashboard";
import Blog from "./Page/Blog/Blog";
import Clients from "./Page/Clients/Clients";
import Contact from "./Page/Contact/Contact";
import Home from "./Page/Home/Home";
import Jobs from "./Page/Jobs/Jobs";
import Login from "./Page/Login/Login";
import New from "./Page/News/New";
import Product from "./Page/Product/Product";
import Register from "./Page/Register/Register";
import UserAdmin from "./Page/UserAdmin/UserAdmin";
import AdminForm from "./Page/AdminForm/AdminForm";
import PrivateRoute from "./routes/PrivateRoute"; // Updated import statement
import Trainee from "./Page/Trainee/Trainee";
import WriteBlog from "./Page/WriteBlog/WriteBlog";
import BlogAdmin from "./Page/BlogAdmin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/client" element={<Clients />} />
        <Route path="/product" element={<Product />} />
        <Route path="/job" element={<Jobs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/writeblog" element={<WriteBlog />} />
        <Route path="/news" element={<New />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* Protected routes */}
        
        <Route path="/user_profile" element={<UserAdmin />} />
        <Route path="/admin" element={<AdminForm />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/trainee" element={<Trainee />} />
        <Route path="/blogadmin" element={<BlogAdmin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
