import React, { useState } from "react";
import "./Contact.css";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Name: ${name}\nEmail: ${email}\nMessage: ${message}`);
    // TODO: Send data to server or API
  };

  return (
    <div>
      <h1  className="text-center fontfa" style={{margin:"15px",padding:"15px"}}>CONTACT US </h1>
      <div className="" style={{margin:"20px"}}>
      
        <div className=" container row">
        
          <div className="col-md-8" style={{ padding: "20px" }}>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Name:</label>
                <input style={{border:"1px solid black"}} className="form-control"
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="email">Email:</label>
                <input style={{border:"1px solid black"}}
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="message">Message:</label>
                <textarea style={{border:"1px solid black"}}
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="col-md-4" style={{ marginTop: "95px" }}>
           
            <i class="fa fa-mobile d-flex ">
              {" "}
              <p  className="fontfa"style={{ marginLeft: "10px", }}>+880 1819250309</p>
            </i>
            <i class="fa-solid fa-phone d-flex fontfa">
              {" "}
              <p style={{ marginLeft: "10px", }}>+8802 550 08199</p>
            </i>
            <i class="fas fa-address-card d-flex fontfa">
              <p style={{ marginLeft: "10px", }}>
                5/9 Block B, Suite B1, Lalmatia, Dhaka 1207, Bangladesh.
              </p>
            </i>
            <i class="fa fa-envelope d-flex " aria-hidden="true"><p className="fontfa" style={{ marginLeft: "10px", }}>freelancingpathshala@gmail.com</p></i> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
