import React from 'react'
import Banner from '../../component/HomeBanner/HomeBanner'
import Nav1 from '../../component/Nav/Nav1'
import homebanner from "../../assets/Image/contactCover.jpg"
import Footer from '../../component/Footer/Footer'
import ContactFrom from "../../component/ContactForm/ContactFrom"

export default function Contact() {
  return (
    <div>
        <Nav1/>
        <Banner image={homebanner} header={"Contact"}/>
<div className="container">
<ContactFrom/>
</div>
        <Footer/>
    </div>
  )
}
