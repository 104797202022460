// import React from 'react';
import 'tailwindcss/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from "../../assets/Image/babusir.jpeg";
import AdminSideMenu from './AdminSideMenu';
import AdminMainHeader from './AdminMainHeader';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      alert('You are Unauthorized');
      navigate('/admin');
    } else {
      setIsTokenChecked(true);
    }
  }, [navigate]);

const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin');
  };


  if (!isTokenChecked) {
    return null; // or a loading spinner
  }

  return (
    <div>
     <div className="bg-gray-100 h-screen flex">
      {/* Sidebar */}
      <AdminSideMenu/>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <AdminMainHeader handleLogout={handleLogout}/>

        {/* Main */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <div className="flex flex-wrap -mx-4">
              {/* Card 1 */}
              <div className="w-full lg:w-1/4 px-4">
                <div className="bg-blue-600 p-5 rounded-lg shadow-xs">
                  <div className="text-white">
                    <h2 className="text-2xl font-semibold">26K</h2>
                    <p>Trainee</p>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div className="w-full lg:w-1/4 px-4">
                <div className="bg-green-600 p-5 rounded-lg shadow-xs">
                  <div className="text-white">
                    <h2 className="text-2xl font-semibold">$6,200</h2>
                    <p>Income</p>
                  </div>
                </div>
              </div>
              {/* Card 3 */}
              <div className="w-full lg:w-1/4 px-4">
                <div className="bg-yellow-400 p-5 rounded-lg shadow-xs">
                  <div className="text-white">
                    <h2 className="text-2xl font-semibold">2.49%</h2>
                    <p>Conversion Rate</p>
                  </div>
                </div>
              </div>
              {/* Card 4 */}
              <div className="w-full lg:w-1/4 px-4">
                <div className="bg-red-600 p-5 rounded-lg shadow-xs">
                  <div className="text-white">
                    <h2 className="text-2xl font-semibold">44K</h2>
                    <p>Sessions</p>
                  </div>
                </div>
              </div>
            </div>

            {/* More content goes here */}
          </div>
        </main>
      </div>
    </div>
    </div>
  )
}

export default AdminDashboard
