import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import AdminMainHeader from '../AdminDashboard/AdminMainHeader';
import AdminSideMenu from '../AdminDashboard/AdminSideMenu';

const Trainee = () => {
  const [traineeData, setTraineeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    fetchTraineeData();
  }, []);

  const fetchTraineeData = () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Token not found');
      setLoading(false);
      return;
    }

    console.log('Token:', token); // Log the token to check its value

    fetch('https://backend.freelancingpathshala.com/api/trainees', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          setError('Unauthorized. Please log in again.');
          localStorage.removeItem('token');
          navigate('/admin');
        } else if (response.status === 403) {
          setError('Forbidden. You do not have permission to view this data.');
        } else {
          setError('Failed to fetch data');
        }
        setLoading(false);
        return;
      }
      return response.json();
    })
    .then(data => {
      console.log("Fetched data:", data);  // Log the fetched data

      if (Array.isArray(data)) {
        setTraineeData(data);
      } else if (data && Array.isArray(data.data)) {
        setTraineeData(data.data);
      } else {
        setError('Unexpected data format');
      }
      setLoading(false);
    })
    .catch(error => {
      setError(error.message);
      setLoading(false);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin');
  };

  return (
    <div className="bg-gray-100 h-screen flex">
      <AdminSideMenu />
      <div className="flex-1 flex flex-col overflow-hidden">
        <AdminMainHeader handleLogout={handleLogout} />
        <div className="m-4">
          <h1 className="text-2xl font-bold mb-4">Trainee Details</h1>
          <table id="example" className="table table-striped table-bordered" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Address</th>
                <th>Educational Level</th>
                <th>Freelancing Experience</th>
                <th>Organization</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {traineeData.map((trainee, index) => (
                <tr key={index}>
                  <td>{trainee.full_name}</td>
                  <td>{trainee.email}</td>
                  <td>{trainee.phone_number}</td>
                  <td>{trainee.address}</td>
                  <td>{trainee.educational_level}</td>
                  <td>{trainee.freelancing_experience}</td>
                  <td>{trainee.organization}</td>
                  <td><img src={trainee.trainee_image_url} alt="Trainee" style={{ width: '50px', height: '50px' }} /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Trainee;
