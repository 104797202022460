import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card'; // Assuming you are using React-Bootstrap

const Blog = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token'); // Replace with your actual token

      try {
        const response = await axios.get('https://backend.freelancingpathshala.com/api/blogs', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setData(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleExpand = (id) => {
    setExpanded(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (

    <>
    <div className="">
    <h1 style={{display:"flex",justifyContent:"center",alignItems:"center",fontSize:"24px",margin:"10px",padding:"10px"}}> Blog</h1> 
    </div>
    <div className="row">
      {data.map(item => (
        <div className="col-md-3" key={item.id}>
          <Card className="lara1" style={{ height: 'auto', alignItems: 'center' }}>
            <Card.Img variant="top" src={item.image_url} />
            <Card.Body>
              <Card.Title className='fontfa' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {item.blog_headline}
              </Card.Title>
              {/* <Card.Text className='fontfa'>
                {item.text} {expanded[item.id] && <>{item.text1}</>}{' '}
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => toggleExpand(item.id)}
                >
                  ... Readmore
                </span>
              </Card.Text> */}
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
    </>
  );
};

export default Blog;
