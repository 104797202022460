import axios from "axios";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/Image/logo_aa.jpg";
// import { useAuth } from "../../context/AuthContext";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../userSlice";
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { login } = useAuth();


  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;
    const data = {
      guard: "trainee",
      email,
      password,
    };

    try {
      const baseUrl = "https://backend.freelancingpathshala.com/api/auth/login";

      const response = await axios.post(baseUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success || response.data.statusCode === 200) {
        console.log(response)
        localStorage.setItem("token", response.data.data.token);
        // login( response.data.data.token);
        const user = response.data.data.personal_info || "";
        console.log(user)
        dispatch(setUserInfo(user));
        navigate("/user_profile");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="loginBody">
      <form onSubmit={handleSubmit} className="card">
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="mb-6"
        >
          <img
            style={{ width: "200px" }}
            src={logo}
            alt="Placeholder logo for a fictional company named PixelStream"
            className="mb-4"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            EMAIL
          </label>
          <input
            id="email"
            name="email"
            className="input-field"
            placeholder="Enter your email or username"
            type="email"
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            PASSWORD
          </label>
          <input
            id="password"
            name="password"
            className="input-field"
            placeholder="password"
            type="password"
            required
          />
        </div>
        <button type="submit" className="btn-primary">
          Sign in
        </button>
        <div className="btn-text">Forgot Password</div>
        <div className="btn-text">
          Don't have an Account? <Link to="/register">Register</Link>
        </div>
        <div className="powered-by">Powered By © TechKnowGram</div>
      </form>
    </div>
  );
};

export default Login;
