import React from "react";
import homeBanner from "../../assets/Image/aboutCover.jpg";
import Footer from "../../component/Footer/Footer";
import Banner from "../../component/HomeBanner/HomeBanner";
import Members from "../../component/MemberShip/Menber";
import Nav1 from "../../component/Nav/Nav1";
import "./About.css";

// import logo from "../../assets/Image/membership/logo.png"
// import logo2 from "../../assets/Image/membership/logo.png"
// import logo3 from "../../assets/Image/membership/logo.png"
// import logo4 from "../../assets/Image/membership/logo.png"
// import logo5 from "../../assets/Image/membership/logo.png"

const membershipLogo = [
  {
    src: require("../../assets/Image/membership/logo.png"),
  },
  {
    src: require("../../assets/Image/membership/logo2.png"),
  },
  {
    src: require("../../assets/Image/membership/logo5.png"),
  },
  {
    src: require("../../assets/Image/membership/logo3.png"),
  },
  {
    src: require("../../assets/Image/membership/logo6.png"),
  },
  {
    src: require("../../assets/Image/she.png"),
  },
];


export default function About() {
  return (
    <div>
      <Nav1 />
      <Banner image={homeBanner} header={"About us"} />
      {/* <div className="container">
        <div className="">
          <h1 className="text-center" style={{ margin: "20px" }}>
            About TechKnowGram Limited
          </h1>
          <p className="text-center">
            TechKnowGram Limited is a global solution provider led by experts
            having more than 25 years of professional experiences in both the
            local and global arena specially for Japan ICT market for last 20
            years as well as USA & Europe market. The company particularly
            provides web-based customized solutions on ERP, AI, Data Analytics,
            Robotics, Embedded Solutions with Beacon, AR, VR Applications and
            Gaming and Animation. TechKnowGram Limited is an ISO 9001: 2015, ISO
            14001: 2015 and ISO/IEC 27001: 2013 certified company and is a
            member of Bangladesh Association of Software BASIS, Bangladesh and
            Japan Chamber of Commerce & Industries (JBCCI), Bangladesh Computer
            Samity BCS, Bangladesh Association of Call Centers and Outsourcing
            BACCO, Ecommerce Association of Bangladesh e-CAB, CTO Forum of
            Bangladesh and SheTrades Commonwealth Program by International Trade
            Centre ITC.
          </p>
        </div>
        <div className="">
          <h1
            className="text-center"
            style={{ margin: "20px", fontFamily: "auto" }}
          >
            Leadership
          </h1>
          <Members />
        </div>

        <h1
          className="text-center"
          style={{ margin: "20px", fontFamily: "auto" }}
        >
          Member
        </h1>
        <div
          className="row"
          style={{ margin: "10px", borderBottom: "2px solid",textAlign:"center"}}
        >
          {membershipLogo.map((item) => (
            <div className="col-md-2 ">
              <img className="membershiblogo" src={item.src} alt="" />
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-md-7"></div>
          <div
            className="col-md-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}
