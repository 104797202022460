import React, { useState } from "react";
import "./Footer.css";

const certification = [
  {
    src: require("../../assets/Image/Certification/c1.png"),
  },
  { src: require("../../assets/Image/Certification/c2.png") },
  { src: require("../../assets/Image/Certification/c3.png") },
];



function Footer() {
  const [about, setAbout] = useState(false);

  const goToabout = (e) => {
    setAbout(!about);
  }
    
  const [isIframeVisible, setIframeVisible] = useState(false);

  const toggleIframe = () => {
    setIframeVisible(!isIframeVisible);
  };
  return (
    <>
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h4 className="fontfa">About Us</h4>
            <p className="fontfa">
            "Freelancing Pathshala” is a television training program on digital course which is going to be aired on Nexus Television from 1st January 2024 in {!about && ("..")}{about && ( <p> collaboration with the Directorate of ICT to achieve the goal of Digital Bangladesh and to improve the skills of the youth society through freelancing. The program will be telecast on Nexus Television as live..</p>)} <p onClick={goToabout} style={{ color: "aquamarine", cursor: "pointer" }}>{about ? "Read less" : "Read more"}</p>
            </p>
          </div>
          <div className="col-md-4"> 
            <a href="/" style={{textDecoration:"none"}}>
            <h3 className="techknow" style={{ color: "aquamarine",cursor:"pointer" }}>
Bangladesh Address</h3></a>
            <p className="fontfa">
               5/9 Block B, Lalmatia, Mohammadpur
             <br /> Dhaka 1207, BANGLADESH
               <br />
              Phone:+88 02 55008199<br /> Mobile/Whatsapp:+88 01819250309 <br />
              Email: info@TechKnowGram.com
            </p>
          </div>
          <div className="col-md-4">
            <h4 className="fontfa">Connect With Us</h4>
            <ul className="social-icons">
              <li>
                <a href="https://www.facebook.com/profile.php?id=61557382131110">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCEl9uppFodQW7b-gXRQ2zUw">
                  <i class="fa fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/100904115/admin/notifications/all/">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
            {/* <div className="row">
            {certification.map((src) => (
            <div className=" certification">
              <img style={{borderRadius:"10px"}} src={src.src} alt="" />
            </div>
          
          ))}
          </div> */}
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="container">
          <div className="" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              <p>
                &copy; All Rights Reserved & Powered By TechKnowGram Limited
              </p>
              <a href="/">
              <img alt="emon" src={require("../../assets/Image/techknowgramlogo.jpg")}width="100"height="35" style={{marginLeft:"5px"}}/>
              </a>
          </div>
        </div>
      </div>
    </footer>

    <button
        id="showIframeButton"
        className={`showIframeButton ${isIframeVisible ? 'closeBtn' : ''}`}
        onClick={toggleIframe}
      >
        <span className="material-symbols-rounded">
          {isIframeVisible ? 'close' : 'smart_toy'}
        </span>
      </button>
      {isIframeVisible && (
        <div id="iframeContainer">
          <iframe
            className="rounded-iframe"
            id="myIframe"
            src="http://englishchatbot.sobjanta.ai/bot"
            frameBorder="0"
          ></iframe>
        </div>
      )}
    </>
  );
}

export default Footer;
