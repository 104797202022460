import React from "react";
import homeBanner from "../../assets/Image/homeBanner.jpg";
import ClientSay from "../../component/ClientsSay/Clients";
import Coursal from "../../component/Coursal/coursal";
import Footer from "../../component/Footer/Footer";
import HomeBanner from "../../component/HomeBanner/HomeBanner";
import Nav1 from "../../component/Nav/Nav1";
import Slider from "react-slick";
import ProductItem from "../../component/product/Product";
import logo from '../../assets/Image/hi_tech..png'
import logo1 from '../../assets/Image/ict_devision.png'
// import logo2 from '../../assets/Image/microsense.png'
// import logo2 from '../../assets/Image/microsense.png'
import logo2 from "../../assets/Image/059.png"
import logo3 from '../../assets/Image/nanma.png'
import "./Home.css";
import Contact from "../../component/ContactForm/ContactFrom";
import Blog from "../../Page/Blog/Blog";


export default function Home() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  return (
    <div>
      <Nav1 />
      {/* <HomeBanner image={homeBanner} header="TechKnowGram" text={"Solution | Comfort | Performance "} /> */}

      <div className="container" style={{marginTop:"100px",paddingTop:"100px"}}>
        {/* <h1 className=""  style={{ margin: "20px" }}>
          Why TechKnowGram{" "}
        </h1> */}
        <div className="row">
          <div className="col-md-6">
           <h1 className="fontfa"  style={{ margin: "20px" }}>
           Freelancing Pathshala{" "}
        </h1>
<span className="fontfa">
        "Freelancing Pathshala” is a television training program on digital course which is going to be aired on Nexus Television from 1st January 2024 in collaboration with the Directorate of ICT to achieve the goal of Digital Bangladesh and to improve the skills of the youth society through freelancing. The program will be telecast on Nexus Television as live.
        </span>
        <button type="button" class="btn btn-secondary">Read more</button>
          </div>
          <div className="col-md-6">
            <Coursal />
          </div>
        </div>
        

        <ProductItem />
<Blog/>

        {/* Partners */}
      

        </div>
        <div>
        <h2 className="text-center" style={{ display:"flex",justifyContent:"center",alignItems:"center",fontSize:"24px",margin:"10px",padding:"10px" }}>Partners</h2>
        <div className="" style={{marginBottom:"30px",}}>
        <Slider {...settings} >
          <div className="lawra">
           <img style={{height:"",width:"",borderRadius:""}} src={logo} alt="" />
           {/* <h3>Kenta Kato</h3> */}
           {/* <p className="text-center">TechKnowGram delivered our work quickly and provided designs that attracted both our clients and partners, while also eliminating the need for a business analyst. They offer a complete solution for all client requirements, making them a valuable asset for any customer.</p> */}
          </div>
          
          <div className="lawra">
          <img  style={{height:"",width:"",borderRadius:""}}src={logo1} alt="" />
          {/* <h3>Hirokazu Yoshida</h3> */}
           {/* <p className="text-center">Ahmedul had been brilliant in getting my work done. He and his team are problem solvers and provided a spontaneous vision of how to build on and clarify whatever was requested. TechKnowGram is praised for its ability to develop workable solutions and for its attention to professional service</p> */}
          </div>
  
         
          <div className="lawra">
          <img style={{height:"",width:"",borderRadius:""}}src={logo2} alt="" />
          {/* <h3>Kinya Ishii</h3> */}
           {/* <p className="text-center">TechKnowGram and Ahmedul worked for us for quite some time. The whole team of Ahmedul became solution provider. They presented a remarkable positive attitude. All the work was done in a short span of time and the team achieved the impossible</p> */}
          </div>
          
          <div className="lawra">
          <img style={{height:"",width:"",borderRadius:""}}src={logo3} alt="" />
          {/* <h3>Malliga Subramanium </h3> */}
           {/* <p className="text-center">TechKnowGram’s team provided fast and flexible service with considerable expertise. Their unique approach to assessing feedback helped me solve my problem. Ahmed, their leader, is highly regarded for his unwavering commitment to justice.</p> */}
          </div>
        </Slider>
        </div>
      


<Contact/>
        
      </div>
      <Footer />
    </div>
  );
}
