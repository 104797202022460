import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../assets/Image/logo_aa.jpg";

const AdminSideMenu = () => {
  return (
    <div>
         <div className="bg-gray-100 h-screen flex">
      <div className="w-64 bg-blue-900 text-white flex flex-col">
        <div  className="p-4 text-white text-2xl font-semibold border-b border-blue-800">
          <Link to={"/admindashboard"} className="" style={{color:"white",textDecoration:"none",display:"flex",justifyContent:"center"}}>
            <img style={{height:"80px",borderRadius:"10px"}} src={logo} alt="" />
          </Link>
        </div>
        <div className="p-4 border-b border-blue-800">
          <div style={{backgroundColor:"none"}}>
            <Link to={"/admindashboard"} className="block py-2.5 px-4 rounded hover:bg-blue-800" style={{color:"white",textDecoration:"none"}}>Dashboard</Link>
            <Link to={"/trainee"}  className="block py-2.5 px-4 rounded hover:bg-blue-800" style={{color:"white",textDecoration:"none"}}>Trainee</Link>
            <Link to={"/blogadmin"}  className="block py-2.5 px-4 rounded hover:bg-blue-800" style={{color:"white",textDecoration:"none"}}>Blog</Link>
          </div>
        </div>
        <div className="flex-grow p-4">
          {/* <nav>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Colors</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Typography</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Base</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Buttons</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Forms</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Charts</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Icons</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Notifications</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Widgets</a>
            <a href="#" className="block py-2.5 px-4 rounded hover:bg-blue-800">Extras</a>
          </nav> */}
        </div>
      </div>
      </div>
    </div>
  )
}

export default AdminSideMenu
