import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Image/logo_aa.jpg";
import "./Registor.css";

const Register = () => {
  const [imageBase64, setImageBase64] = useState("");
  const [fileType, setFileType] = useState("");
  const [languageProficiency, setLanguageProficiency] = useState([]);
  const [skills, setSkill] = useState([]);
  const [educationLevel, setEducationLevel] = useState([]);
  const [PortfolioAccount, setPortfolioAccount] = useState([]);
  const [doneTraining, setDoneTraining] = useState([]);
  const [wantTraining, setWantTraining] = useState([]);
  const navigate = useNavigate();
  // const { login } = useAuth();

  const handleLanguageProficiency = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      // Checkbox is checked, add language proficiency
      setLanguageProficiency((prevProficiencies) => [
        ...prevProficiencies,
        value,
      ]);
    } else {
      // Checkbox is unchecked, remove language proficiency
      setLanguageProficiency((prevProficiencies) =>
        prevProficiencies.filter((proficiency) => proficiency !== value)
      );
    }
  };
  console.log(languageProficiency)
  const handleskillProficiency = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      // Checkbox is checked, add language proficiency
      setSkill((prevProficiencies) => [
        ...prevProficiencies,
        value,
      ]);
    } else {
      // Checkbox is unchecked, remove language proficiency
      setSkill((prevProficiencies) =>
        prevProficiencies.filter((proficiency) => proficiency !== value)
      );
    }
  };
  console.log(skills);

  const handleEducationLevelProficiency = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      // Checkbox is checked, add language proficiency
      setEducationLevel((prevProficiencies) => [
        ...prevProficiencies,
        value,
      ]);
    } else {
      // Checkbox is unchecked, remove language proficiency
      setEducationLevel((prevProficiencies) =>
        prevProficiencies.filter((proficiency) => proficiency !== value)
      );
    }
  };

  console.log(educationLevel);


  const handlePortfolioAccountProficiency = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      // Checkbox is checked, add language proficiency
      setPortfolioAccount((prevProficiencies) => [
        ...prevProficiencies,
        value,
      ]);
    } else {
      // Checkbox is unchecked, remove language proficiency
      setPortfolioAccount((prevProficiencies) =>
        prevProficiencies.filter((proficiency) => proficiency !== value)
      );
    }
  };
  console.log(PortfolioAccount);



  const handleDoneTrainingProficiency = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      // Checkbox is checked, add language proficiency
      setDoneTraining((prevProficiencies) => [
        ...prevProficiencies,
        value,
      ]);
    } else {
      // Checkbox is unchecked, remove language proficiency
      setDoneTraining((prevProficiencies) =>
        prevProficiencies.filter((proficiency) => proficiency !== value)
      );
    }
  };
  console.log(doneTraining);


  const handleWantTrainingProficiency = (e) => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    if (isChecked) {
      // Checkbox is checked, add language proficiency
      setWantTraining((prevProficiencies) => [
        ...prevProficiencies,
        value,
      ]);
    } else {
      // Checkbox is unchecked, remove language proficiency
      setWantTraining((prevProficiencies) =>
        prevProficiencies.filter((proficiency) => proficiency !== value)
      );
    }
  };
  console.log(wantTraining);


  
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageBase64(reader.result);
    };

    if (file) {
      const fileType = file.type;
      setFileType(fileType);

      reader.readAsDataURL(file);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const full_name = event.target.full_name.value;
    const email = event.target.email.value;
    const phone_number = event.target.phone_number.value;
    const address = event.target.address.value;
    const skills = event.target.skills.value; // Convert skills to array
    const educational_level = event.target.educational_level.value;
    const freelancing_experience = event.target.freelancing_experience.value;
    //   const portfolio_link = event.target.portfolio_link.value;
    const organization = event.target.organization.value;
    const test = event.target.language_proficiency.value;
    console.log();
   

    const done_trainings = event.target.done_trainings.value;
    const wantTo_trainings = event.target.wantTo_trainings.value;
    const password = event.target.password.value;

   
    const data = {
      full_name,
      email,
      phone_number,
      address,
      skills:skills,
      educational_level:educationLevel[0],
      freelancing_experience,
      portfolio_link: null,
      organization,
      language_proficiency: languageProficiency,
      done_trainings:doneTraining,
      wantTo_trainings:wantTraining,
      password,
      trainee_image: {
        type: fileType,
        base64_string: imageBase64,
      },
    };

    try {
      const baseUrl = "https://backend.freelancingpathshala.com/api/trainees";

      const response = await axios.post(baseUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success || response.data.statusCode === 200) {
        alert("Registeration Successfull");
        // login( response.data.data.token);
        navigate("/login");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div class="container mx-auto mt-10 p-8 bg-white shadow-lg max-w-4xl">
        <div class="flex justify-center items-center mb-8">
          <div className="">
            <a href="/">
              <img
                style={{ height: "7rem" }}
                src={logo}
                alt="Dynamic colorful logo placeholder"
                class="h-12"
              />
            </a>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <label for="fullName" class="block text-gray-700 mb-2">
                Full Name *
              </label>
              <input
                type="text"
                id="fullName"
                name="full_name"
                placeholder="Enter your Full Name"
                required
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label for="email" class="block text-gray-700 mb-2">
                Email (The email address should be unique.) *
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <label for="organization" class="block text-gray-700 mb-2">
                Organization
              </label>
              <input
                type="text"
                id="organization"
                name="organization"
                placeholder="Enter your organization name"
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label for="phone" class="block text-gray-700 mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone_number"
                placeholder="Enter your phone number"
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            {/* <div>
                    <label for="education" class="block text-gray-700 mb-2">Educational Level</label>
                    <input type="text" id="education" name="educational_level"  class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
                </div> */}
            <div>
              <label for="address" class="block text-gray-700 mb-2">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Enter your address"
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label
                for="freelancing_experience"
                class="block text-gray-700 mb-2"
              >
                Freelancing Experience
              </label>
              <div class="relative">
                <select
                  id="freelancing_experience"
                  class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  name="freelancing_experience"
                >
                  <option selected>0-1 year</option>
                  <option>1-3 years</option>
                  <option>3-5 years</option>
                  <option>more than 5 years</option>
                </select>
              </div>
            </div>
          </div>
          <fieldset>
            <legend>Skill:</legend>

            <div className="alin">
            <input
                type="checkbox"
                id="Programmer"
                value="Programmer"
                name="skills"
                onChange={(e) => handleskillProficiency(e)}
              />
              <label className="mara" for="programmer">
                Programmer
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="designer" value='Designer' name="skills" onChange={(e)=>handleskillProficiency(e)} />
              <label className="mara" for="designer">
                Designer
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="other1" value='Other' name="skills" onChange={(e)=>handleskillProficiency(e)}  />
              <label className="mara" for="other">
                Other
              </label>
            </div>
          </fieldset>

          {/* <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6"> */}
          {/* <div>
                    <label for="freelancing_experience" class="block text-gray-700 mb-2"> Which Portfolio you have:</label>
                    <div class="relative">
            <select id="freelancing_experience" class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500" name='portfolio_link'>
                <option >Fiverr</option>
                <option >Upwork</option>
                <option>Freelancer</option>
                <option>Toptal</option>
                <option>Guru</option>
                <option>Other</option>
            </select>

        </div>
                </div> */}
          {/* <div>
                    <label for="freelancing_experience" class="block text-gray-700 mb-2">Language Proficiency</label>
                    <div class="relative">
            <select id="freelancing_experience" class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500" name='language_proficiency'>
                <option selected>Bangla</option>
                <option >English</option>
                <option> Other</option>
            </select>

        </div>
                </div> */}
          {/* </div> */}

          <fieldset>
            <legend>Educational Level:</legend>

            <div className="alin">
              <input type="checkbox" id="SSC"value="SSC" name="educational_level"  onChange={(e) => handleEducationLevelProficiency(e)} />
              <label className="mara" for="SSC">
                SSC
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="HSC" name="educational_level" value='HSC' onChange={(e) => handleEducationLevelProficiency(e)} />
              <label className="mara" for="HSC">
                HSC
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="other2" name="educational_level" value="other" onChange={(e) => handleEducationLevelProficiency(e)} />
              <label className="mara" for="other">
                Other
              </label>
            </div>
          </fieldset>

          <fieldset>
            <legend>Which Portfolio you have:</legend>

            <div className="alin">
              <input type="checkbox" id="fiver" value='Fiverr' name="portfolio_link" onChange={(e)=>handlePortfolioAccountProficiency(e)}  />
              <label className="mara" for="fiver">
                Fiverr
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="upwork" value='Upwork' name="portfolio_link"  onChange={(e)=>handlePortfolioAccountProficiency(e)}/>
              <label className="mara" for="upwork">
                Upwork
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="freelancer" value='Freelancer' name="portfolio_link" onChange={(e)=>handlePortfolioAccountProficiency(e)}  />
              <label className="mara" for="freelancer">
                Freelancer
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="topal" value='Topal' name="portfolio_link"  onChange={(e)=>handlePortfolioAccountProficiency(e)}  />
              <label className="mara" for="topal">
                Toptal
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="guru" value='Guru' name="portfolio_link" onChange={(e)=>handlePortfolioAccountProficiency(e)}   />
              <label className="mara" for="guru">
                Guru
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="other3" value='Other' name="portfolio_link" onChange={(e)=>handlePortfolioAccountProficiency(e)}  />
              <label className="mara" for="other">
                Other
              </label>
            </div>
          </fieldset>

          <fieldset>
            <legend>Language Proficiency:</legend>

            <div className="alin">
              <input
                type="checkbox"
                id="bangla"
                value="bangla"
                name="language_proficiency"
                onChange={(e) => handleLanguageProficiency(e)}
              />
              <label className="mara" for="bangla">
                Bangla
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="english" value="english" name="language_proficiency"  onChange={(e) => handleLanguageProficiency(e)} />
              <label className="mara" for="english">
                English
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="other4" value="other4" name="language_proficiency" onChange={(e) => handleLanguageProficiency(e)} />
              <label className="mara" for="other">
                Other
              </label>
            </div>
          </fieldset>

          <fieldset>
            <legend>Is there any training that you have done:</legend>

            <div className="alin">
              <input type="checkbox" value='Copywriting' id="copywriting" name="done_trainings" onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="Copywriting">
                Copywriting
              </label>
            </div>
            <div className="alin">
              <input
                type="checkbox"
                id="digital_marketing"
                name="done_trainings" 
                value='Digital Marketing'
                onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="digital_marketing">
                Digital Marketing
              </label>
            </div>
            <div className="alin">
              <input
                type="checkbox"
                id="graphic_design"
                name="done_trainings"
                value='Graphic Design'
                onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="graphic_design">
                Graphic Design
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="data_entry" name="done_trainings" value='Data Entry'  onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="data_entry">
                Data Entry
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="seo" value=' SEO' name="done_trainings" onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="seo">
                SEO
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="ui_design" name="done_trainings" value=' UX/UI Design' onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="ui_design">
                UX/UI Design
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="other5" value='Other' name="done_trainings" onChange={(e) => handleDoneTrainingProficiency(e)} />
              <label className="mara" for="other">
                Other
              </label>
            </div>
          </fieldset>

          <fieldset>

            <legend>Is there any training you are interested to do:</legend>

            <div className="alin">
              <input
                type="checkbox"
                id="copywriting1"
                name="wantTo_trainings"
                value='Copywriting'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="Copywriting1">
                Copywriting
              </label>
            </div>
            <div className="alin">
              <input
                type="checkbox"
                id="digital_marketing1"
                name="wantTo_trainings"
                value=' Digital Marketing'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="digital_marketing1">
                Digital Marketing
              </label>
            </div>
            <div className="alin">
              <input
                type="checkbox"
                id="graphic_design1"
                name="wantTo_trainings"
                value='  Graphic Design'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="graphic_design1">
                Graphic Design
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="data_entry1" name="wantTo_trainings" value='   Data Entry'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="data_entry1">
                Data Entry
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="seo1" name="wantTo_trainings" value='SEO'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="seo1">
                SEO
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="ui_design1" name="wantTo_trainings" value=' UX/UI Design'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="ui_design1">
                UX/UI Design
              </label>
            </div>
            <div className="alin">
              <input type="checkbox" id="other6" name="wantTo_trainings" value='Other'
                onChange={(e) => handleWantTrainingProficiency(e)} />
              <label className="mara" for="other6">
                Other
              </label>
            </div>
          </fieldset>

          {/* <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6"> */}
          {/* <div>
                    <label for="freelancing_experience" class="block text-gray-700 mb-2">Is there any training that you have done</label>
                    <div class="relative">
            <select id="freelancing_experience" class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500" name='done_trainings'>
                <option selected>Copywriting</option>
                <option >Digital Marketing</option>
                <option>Graphic Design</option>
                <option> Data Entry</option>
                <option> SEO</option>
                <option>UX/UI Design</option>
                <option>Other</option>
            </select>

        </div>
                </div> */}

          {/* <div>
                    <label for="freelancing_experience" class="block text-gray-700 mb-2">Is there any training you are interested to do</label>
                    <div class="relative">
            <select id="freelancing_experience" class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2.5 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500" name='wantTo_trainings'>
                <option selected>Copywriting</option>
                <option >Digital Marketing</option>
                <option>Graphic Design</option>
                <option> Data Entry</option>
                <option> SEO</option>
                <option>UX/UI Design</option>
                <option>Other</option>
            </select>

        </div>
                </div> */}
          {/* </div> */}

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <legend>Password</legend>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password "
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <div className="mb-4">
                <legend
                  htmlFor="fileInput"
                  className="block text-gray-700 mb-2"
                >
                  Upload Your Image
                </legend>
                <input
                  type="file"
                  id="fileInput"
                  name="imageUpload"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  onChange={handleFileInputChange}
                />
                {imageBase64 && (
                  <div>
                    <p>File Type: {fileType}</p>
                    <img
                      src={imageBase64}
                      alt="Uploaded"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <button
              type="submit"
              class="bg-blue-500 text-white px-6 py-2 rounded focus:outline-none hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
