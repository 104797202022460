import React from 'react'
import Banner from '../../component/HomeBanner/HomeBanner'
import Nav1 from '../../component/Nav/Nav1'
import homeBanner from "../../assets/Image/ProductCover.jpg"
import ProductSlide from '../../component/product/Product'
import Footer from '../../component/Footer/Footer'


export default function Product() {
  return (
    <div>
        <Nav1/>
        <Banner image={homeBanner}header={" Services"}/>
        <div className="container">
        {/* <h1 className="text-center" style={{ margin: "20px" }}>
          Product & Services 
        </h1> */}
        <ProductSlide/>
        </div>
        <Footer/>
    </div>
  )
}
