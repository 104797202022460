import React from 'react'
import Banner from '../../component/HomeBanner/HomeBanner'
import Nav1 from '../../component/Nav/Nav1'
import homebanner from '../../assets/Image/jobsCover.jpeg'
import CollopassForJob from '../../component/CollopsForJobs/CollopassForJob'
import Footer from '../../component/Footer/Footer'

export default function Jobs() {
  return (
    <div>
        <Nav1/>
        <Banner image={homebanner} header={"Partners"}/>
        {/* <div className="container" >
            <CollopassForJob/>

            
        </div> */}
        <Footer/>
    </div>
  )
}
