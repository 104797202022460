import axios from "axios";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Image/logo_aa.jpg";

export default function WriteBlog() {
  const [imageBase64, setImageBase64] = useState("");
  const [fileType, setFileType] = useState("");
  const token = localStorage.getItem('token');
  const navigate = useNavigate();



  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageBase64(reader.result);
    };
  

    if (file) {
      const fileType = file.type;
      setFileType(fileType);
      reader.readAsDataURL(file);
      console.log('image:',setImageBase64);

    }
  };


  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const topic = event.target.topic.value;
    const blog_headline = event.target.blog_headline.value;
    const blog_details = event.target.blog_details.value;
   
   
    const data = {
      topic,
      blog_headline,
      blog_details,
      image: {
        type: fileType,
        base64_string: imageBase64,
      },
    };

    try {
      const baseUrl = "https://backend.freelancingpathshala.com/api/blogs";

      const response = await axios.post(baseUrl, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.success || response.data.statusCode === 200) {
        alert("Blog Successfull");
        // login( response.data.data.token);
        navigate("/user_profile");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  return (
    <div>
       
       <div class="container mx-auto mt-10 p-8 bg-white shadow-lg max-w-4xl">
        <div class="flex justify-center items-center mb-8">
          <div className="">
            <a href="/">
              <img
                style={{ height: "7rem" }}
                src={logo}
                alt="Dynamic colorful logo placeholder"
                class="h-12"
              />
            </a>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <label for="fullName" class="block text-gray-700 mb-2">
               Topic Name
              </label>
              <input
                type="text"
                id="topic"
                name="topic"
                placeholder="Enter your Topic Name"
                required
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <label for="email" class="block text-gray-700 mb-2">
               Blog Title 
              </label>
              <input
                type="text"
                id="blog_title"
                name="blog_headline"
                placeholder="Enter your Blog Title"
                required
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div>
              <label for="organization" class="block text-gray-700 mb-2">
                Blog Details:
              </label>
              <textarea
                type="message"
                id="message"
                name="blog_details"
                placeholder="Enter your Blog Details"
                class="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
                <legend
                  htmlFor="fileInput"
                  className="block text-gray-700 mb-2"
                >
                  Upload Your Image
                </legend>
                <input
                  type="file"
                  id="fileInput"
                  name="imageUpload"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  onChange={handleFileInputChange}
                />
                {imageBase64 && (
                  <div>
                    <p>File Type: {fileType}</p>
                    <img
                      src={imageBase64}
                      alt="Uploaded"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                )}
              </div>
            
          </div>
          

        

          

          


        

         



          <div class="flex justify-end">
            <button
              type="submit"
              class="bg-blue-500 text-white px-6 py-2 rounded focus:outline-none hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
       
    </div>
  )
}
