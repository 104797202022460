import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate,Link } from "react-router-dom";
import 'tailwindcss/tailwind.css';
import logo from "../../assets/Image/logo_aa.jpg";

const UseAdmin = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      alert('You are Unauthorized');
      navigate('/login');
    } else {
      setIsTokenChecked(true);
    }
  }, [navigate]);

const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };


  if (!isTokenChecked) {
    return null; // or a loading spinner
  }

  return (
    <div className="bg-blue-100 min-h-screen">
      <div className="container mx-auto p-4">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <Link to='/'>
            <img  style={{height:"70px",borderRadius:"5px"}}
              src={logo}
              alt="Placeholder logo with abstract shapes and vibrant colors"
              className="mr-2"
            />
            </Link>
            <h1 className="text-3xl font-bold text-purple-700">Profile</h1>
          </div>
          <div className="space-x-4">
            <button className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition duration-300"> <Link to='/writeblog'>Write a Blog</Link>
              
            </button>
            <button className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition duration-300"  onClick={handleLogout}> 
              Log Out
            </button>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-purple-700">
                hi! This {userInfo.full_name}
            </h2>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
              Home
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-gray-100 p-4 rounded-lg">
              <img
                src={userInfo.trainee_image_url}
                alt="Placeholder profile image"
                className="mb-4"
              />
              <h3 className="text-lg font-semibold mb-2">{userInfo.full_name}</h3>
              <p className="text-gray-600 mb-2">{userInfo.address}</p>
            </div>
            <div className="col-span-1 md:col-span-2">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="full_name">
                  Full Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="full_name"
                  type="text"
                  value={userInfo.full_name}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="text"
                  value={userInfo.email}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                  Phone
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="phone"
                  type="text"
                  value={userInfo.phone_number}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="organization">
                  Organization
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="organization"
                  type="text"
                  value={userInfo.organization}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                  Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address"
                  type="text"
                  value={userInfo.address}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="done_trainings">
                  Done Trainings
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="done_trainings"
                  type="text"
                  value={userInfo.done_trainings.join(', ')}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="wantTo_trainings">
                  Want to Trainings
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="wantTo_trainings"
                  type="text"
                  value={userInfo.wantTo_trainings.join(', ')}
                  readOnly
                />
              </div>
              <div className="flex justify-between items-center">
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                  EDIT
                </button>
                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300">
                  EDIT Password
                </button>
                <div className="flex items-center">
                  <span className="text-gray-700 mr-2">Your Approval Status</span>
                  <span className="bg-blue-200 text-blue-800 px-4 py-1 rounded-full">
                    Approved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseAdmin;
