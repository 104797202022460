import logo from "../../assets/Image/babusir.jpeg";
import React from 'react';

const AdminMainHeader = ({handleLogout}) => {
  return (
    <div>
      <header className="flex justify-between items-center p-4 bg-white border-b border-gray-200">
          <div className="flex items-center space-x-4">
            <button className="text-gray-500 focus:outline-none lg:hidden">
              <i className="fas fa-bars"></i>
            </button>
            <h1 className="text-lg font-semibold text-gray-800"></h1>
          </div>
          <div className="flex items-center space-x-4">
            <i className="fas fa-search text-gray-600"></i>
            <i className="far fa-bell text-gray-600"></i>
            <i className="far fa-envelope text-gray-600"></i>
            <button onClick={handleLogout}>  <img  className="h-8 w-8 rounded-full object-cover" src={logo} alt="Profile" /> </button>
           
          </div>
        </header>
    </div>
  )
}

export default AdminMainHeader
