import React from 'react'
import Footer from '../../component/Footer/Footer'
import Banner from '../../component/HomeBanner/HomeBanner'
import Nav1 from '../../component/Nav/Nav1'
import logo from "../../assets/Image/NewsCover.jpeg"

export default function New() {
  return (
    <div>
        <Nav1/>
        <Banner image={logo} header={"News"}/>
        <Footer/>
    </div>
  )
}
